* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

#particles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
